.searchBlock,
.searchBlock > * {
    display: flex;
    justify-content: space-between;
}

.searchBlock-split {
    width: 50%;
    & > * {
        flex-grow: 0;
        margin-left: 15px;
        flex-basis: 0;
    }
    & > *:first-child {
        flex-grow: 4;
        margin-left: 0;
    }
}