$slide-height: 25%;

.slider {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: $slide-height;
    margin-bottom: 2%;
    button {
        display: none;
    }

    .slider-item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        & > * {
            background-position: center;
            background-size: cover;
            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 0;
                padding-top: $slide-height;
            }
        }
    }
}