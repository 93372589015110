.Home .lander {
    padding: 80px 0;
    text-align: center;
}


.Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}
