* > .subMenu {
    animation: rotateMenu 500ms ease-in-out forwards;
    transform-origin: top center;
    flex-direction: column;
    padding: 0 15px 0 0;
}

* > .subMenu {
    display: none;
    background-color: rgba(40,40,42,0.75);
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
    float: left;
    position: absolute !important;
    flex-direction: column;
    top: 100%;
    right: 0;
    z-index: 99999;
    padding-right: 0;
    height: 0;
}

*:hover > .subMenu {
    animation: fade_in 0.1s forwards;
    display: block;
    height: auto;
    @include transition(height 0.25s, opacity 0.3s);
}

.subMenu-item:after {
    content: "";
    background-color: $color-background-brand;
    display: block;
    height: 0;
    width: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: $layer-back;
    @include transition(height 0.25s, opacity 0.3s);
}

.subMenu-item {
    position: relative;
    &:hover {
        color: $color-linkWt;
        &:after {
            display: block;
            bottom: 0;
            height: 100%;
            opacity: 1;
        }
    }
}

.subMenu-item > *,
.subMenu-item > *:visited,
.subMenu-item > *:hover,
.subMenu-item > *:focus {
    padding: 0.75em 1.75em;
    white-space: nowrap;
    font-size: 1rem;
    display: inline-block;
    text-decoration: none;
    color: $color-linkWt;
    position: relative;
    z-index: 10;
}