.block {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    list-style: none;
    margin: 0px 0 0;
    padding: 0;
    & > * {
        padding-top: 0px;
        flex: 1 100%;
        & > * {
            // height: 100%;
        }
    }
}

@for $i from 1 through 12 {
    .block_#{$i}up > * {
        box-sizing: border-box;
        width: 100%;
        max-width: 100%;
        @include breakpoint(tablet) {
            max-width: 50%;
        }
        @include breakpoint(desktop) {
            max-width: 100% / $i;
        }
    }
}

.mix-block_gutter {
    margin: -15px -10px 0;
    & > * {
        margin: 0;
        padding: 15px 10px 0;
    }
}

.mix-block_gutterSm {
    margin: -5px -5px 0 0;
    & > * {
        margin: 0;
        padding: 5px 5px 0 0;
    }
}