body {
  background-color: #D3D7D6;
  margin: 0;
  padding: 0;
  color: #000000;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: $font-code;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1, h2, h3, h4, h5, h6 {
    font-family: inherit;
}
select.form-control,
textarea.form-control,
input.form-control {
    font-size: 1rem;
}
input[type=file] {
    width: 100%;
}

input[type=submit],
input[type=button] {
  border: none;
}

p {
  margin: 15px 0;
}

a,
button {
  border: none;
  border-width: 0px;
  border-style: none;
  border-color: transparent;
  border-image: none;
  font: inherit;
}

ul, 
ol {
  margin: 0 0 1.5em 3em;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1.5em;
}

.ATTN {
  color: $color-error;
  font-size: 1.125rem;
}

.txt_callOut {
  color: $color-text-brand;
}