/* Force footer to the bottom of page */
.siteContainer {
    position: relative;
    min-height: 100vh;
    padding-bottom: 0;
    @include breakpoint(tablet) {
        padding-bottom: 420px;
    }
}

.footer {
    max-height: 100%;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    overflow: hidden;
    @include breakpoint(tablet) {
        max-height: 450px;
        padding-top: 5%;
        // position: absolute;
    }
}

.footer > * {
    @include clearfix;
    font-size: 0;
    &> * {
        font-size: 1rem;
    }
}

.footer-bd {
    background-color: #58595B;
    position: relative;
    padding: 7% 0;
    overflow: hidden;
    @include breakpoint(tablet) {
        padding: 2% 0;
    }
}

.footer-base {
    font-size: $font-size-smaller;
    background-color: #58595B;
    color: $color-base-white;
    padding: 10px 0;
}

.footer-base > * {
    font-size: $font-size-smaller; 
}

.footer-bd-bg {
    width: 700px;
    position: absolute;
    top: -11%;
    left: 58%;
    fill: $color-background-bodyLt;
    opacity: 0.1;
    pointer-events: none;
}