.branding {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    font-size: 0;
    padding: 10px 0;
    & > * {
        width: 100%;
    }
}

.branding_light {
    color: $color-base-white;
    fill: $color-base-white;
}

.branding_short {
    width: 20%;
    max-width: 250px;
}