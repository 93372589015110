.legal {
    color: $color-text-md;
    font-size: $font-size-smaller;
}

.legal > * {
    display: block;
}

.legal-link {
    color: $color-text-lt;
    &:hover {
        color: $color-base-white;
    }
}