// Rem output with px fallback
@mixin font-size($sizeValue: 1) {
	font-size: ($sizeValue * 16) * 1px;
	font-size: $sizeValue * 1rem;
}

// Center block
@mixin center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

// Clearfix
@mixin clearfix() {
	*zoom: 1;
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}

// Clear after (not all clearfix need this also)
@mixin clearfix-after() {
	clear: both;
}

// Column width with margin
@mixin column-width($numberColumns: 3) {
	width: map-get( $columns, $numberColumns ) - ( ( $columns__margin * ( $numberColumns - 1 ) ) / $numberColumns );
}

// Sets up border box box-sizing for prefixs
@mixin border-box {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; 
}


@mixin button {
    & > * {
        background-color: $color__background-brand;
        border-radius: 3px;
        box-sizing: border-box;
        color: $color__text-wt;
        display: inline-block;
        font-size: $font__size-med;
        padding: 10px;
        width: 100%;
        text-align: center;
        text-decoration: none;
    }
    & > *:hover,
    & > *:visited,
    & > *:focus {
        text-decoration: inherit;
        color: $color__text-wt;
        outline: none;
    }
    & > *:hover {
        background-color: $color__background-btnhover;
    }
}