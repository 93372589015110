h1, h2, h3, h4, h5, h6 {
	clear: both;
}

.hdg {
	margin: 0;
	text-transform: uppercase;
	font-weight: 400;
	& a:hover {
		text-decoration: none;
	}
}

.hdg_max {
	font-size: $font-size-huge;
	@include breakpoint(tablet) {
		font-size: $font-size-max
	}

}

.hdg_1 {
	font-size: $font-size-biggest;
	@include breakpoint(tablet) {
		font-size: $font-size-huge;
	}
}

.hdg_2 {
	font-size: $font-size-big;
	@include breakpoint(tablet) {
		font-size: $font-size-biggest;
	}
}

.hdg_3 {
	font-size: $font-size-med;
	@include breakpoint(tablet) {
		font-size: $font-size-bigger;
	}
}

.hdg_4 {
	font-size: $font-size-med;
	@include breakpoint(tablet) {
		font-size: $font-size-big;
	}
}

.hdg_5 {
	font-size: $font-size-base;
	@include breakpoint(tablet) {
		font-size: $font-size-med;
	}
}

.hdg_6 {
	font-size: $font-size-base;
}

.mix-hdg_subtle {
	text-transform: none;
	font-weight: 100;
}

.mix-hdg_thick {
	font-weight: 700;
}

.mix-hdg_label {
	color: $color-text-brand;
}

.mix-hdg_light {
	color: $color-text-lt;
}

.mix-hdg_pushMd {
	text-align: left;
	padding-left: 4%;
}

.mix-hdg_brand {
	color: $color-text-brand;
}

.mix-hdg_dark {
	color: $color-text-dk;
}

.mix-hdg_reg {
	text-transform: none;
}