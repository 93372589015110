/* Primary Navigation Menu */
.menu {
    display: flex;
    height: 100%;
    flex-direction: column;
    position: relative;
    @include breakpoint(desktop) {
        // max-width: 75%;
        flex-direction: row;
    }
}

.menu-item {
    // position: relative;
    text-transform: uppercase;
    text-align: right;
    @include breakpoint(desktop) {
        position: relative;
    }
}

.menu-item:after {
    content: "";
    background-color: #28282A;
    display: block;
    height: 0;
    width: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: $layer-back;
    @include transition(height 0.25s, opacity 0.3s);
}

@include breakpoint(desktop) {
    .menu-item {
        text-align: center;
        &:hover {
            color: $color-linkWt;
            &:after {
                display: block;
                bottom: 0;
                height: 100%;
                opacity: 1;
            }
        }
        .menu-item {
            text-align: left;
        }
    }
}

.menu-item-link,
.menu-item-link:visited,
.menu-item-link:hover,
.menu-item-link:focus {
    color: #FFFFFF;
    box-sizing: border-box;
    padding: 0.75em 2em;
    position: relative;
    z-index: $layer-back-ft;
    display: inline-block;
    text-decoration: none;
    display: flex;
    justify-content: flex-end;
    height: 100%;
    @include breakpoint(desktop) {
        padding: 2.6em 0.75em;
    }
}

.menu-item_active > * {
    color: $color-link !important;
}

.menu-item_active:hover > * {
    color: $color-linkWt !important;
}

/* Menu animation */
@keyframes slide_in_right {
	100% {
		transform: translateX(0%);
	}
}

@keyframes fade_in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
