.cell,
.cell-int-rw {
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    text-align: center;
    border: solid 1px $color-border;
    border-top: none;
    width: 100%;
}

.cell_int {
    border: none;
}

.cell_int + .cell_int {
    border-top: solid 1px $color-border;
}

.mix-cell_top {
    border-top: solid 1px $color-border;
}

.cell > * {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 1rem;
    box-sizing: border-box;
    background-color: transparent;
    margin: 1px;
    overflow-wrap: break-word;
    padding: 10px ;
    color: #000000;
    margin: 0;
    & + * {
        border-left: solid 1px $color-border;
    }
}

.cell-int_xs {
    flex-grow: 0;
}

.cell-int_sm {
    flex-grow: 1;
}

.cell-int_md {
    flex-grow: 2;
}

.cell-int_lg {
    flex-grow: 3;
}

.cell_int[data-status] {
    & > *:first-child {
        position: relative;
        & > * {
            display: inline;
            max-width: 70%;
            position: relative;
            z-index: 500;
        }
        &:before {
            content: "";
            background-color: transparent;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            margin-top: -15px;
            position: absolute;
            left: 20px;
            top: 50%;
            z-index: 0;
        }
    }
}

.cell_int[data-status="green"] {
    & > *:first-child {
        &:before {
            content: "";
            background-color: #11cf41;
            // border-left: 80px solid #32b2b2;
        }
    }
}
.cell_int[data-status="yellow"] {
    & > *:first-child {
        &:before {
            content: "";
            background-color: #f0cd0b;
            // border-left: 80px solid #f7c12f;
        }
    }
}

.cell_int[data-status="red"] {
    & > *:first-child {
        &:before {
            content: "";
            background-color: #e00303;
            // border-left: 80px solid #e00303;
        }
    }
}

.cell-int > * {
    margin: 0;
    font-size: 0.875rem;
}

.cell-int_bd {
    display: block;
    background-color: transparent;
    padding: 0;
    margin: 0;
    flex-grow: 6;
}

.mix-cell-int_txtL {
    text-align: left;
    justify-content: flex-start;
}