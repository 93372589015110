$font-main: "Open Sans", sans-serif;
$font-code: "Open Sans", Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
$font-pre: "Open Sans", "Courier 10 Pitch", Courier, monospace;
$font-line-height-body: 1.5;
$font-line-height-pre: 1.6;

$font-size-max: 3.875rem;
$font-size-huge: 3rem;
$font-size-biggest: 2rem;
$font-size-bigger: 1.5rem;
$font-size-big: 1.375rem;
$font-size-med: 1.125rem;
$font-size-base: 1rem;
$font-size-small: 0.875rem;
$font-size-smaller: 0.75rem;
$font-size-smallest: 0.625rem;