
/* Site Map In Footer */
.sitemap {
	width: 100%;
	text-align: center;
	color: $color-link;
	& > * {
		padding: 0 15px;
		&:visited {
			color: $color-link;
		}
		&:hover {
			color: $color-link-hoverLt;
		}
	}
	& > * + * {
		border-left: solid 2px $color-border;
	}
}