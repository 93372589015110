.form {
    margin: 0 auto;
}

.form_sm {
    max-width: 930px;
}

.form-captcha {
    display: flex;
    flex-direction: column;
    background-color: $color-background-bodyWt;
    border-radius: 25px;
    max-width: 80%;
    margin: 15px auto;
    padding: 15px 25px;
    color: $color-text-dk;

    @include breakpoint(tablet) {
        max-width: 50%;
    }

    > * + * {
        margin-top: 15px;
    }
}