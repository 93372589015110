@media all and (min-width: 480px) {
  .LandingPage form {
      margin: 0 auto;
      //max-width: 320px;
  }
  
  .modal {
      display: none;
      position: fixed;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: rgba(211, 215, 214, 0.25);
  }

  .modal_content {
      background-color: white;
      position: absolute;
      top: 20%;
      left: 30%;
      width: 40%;
      padding: 20px;
      border-radius: 5px;
      border: 2px solid black;
  }

}
