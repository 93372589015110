$color-base-black: #000000;
$color-base-offWhite: #dfe0e5;
$color-base-white: #ffffff;

$color-grey-dark: #28282A;
$color-grey-slate: #58595B;
$color-grey-light: #7C7D80;

$color-swx-orange: #ED6622;
$color-orange-dark: #C4531B;
$color-error: #bf022e;

$color-screen-light: rgba(255, 255, 255, 0.25);
$color-screen-medium: rgba(255, 255, 255, 0.5);
$color-screen-heavy: rgba(255, 255, 255, 0.85);

$color-tint-light: rgba(124, 125, 128, 0.25);
$color-tint-medium: rgba(124, 125, 128, 0.5);
$color-tint-heavy: rgba(124, 125, 128, 0.85);

$color-background-body: $color-base-black;
$color-background-bodyDk: $color-grey-dark;
$color-background-bodyMd: $color-grey-slate;
$color-background-bodyLt: $color-grey-light;
$color-background-bodyOw: $color-base-offWhite;
$color-background-bodyWt: $color-base-white;
$color-background-screen: #f1f1f1;
$color-background-hr: #ccc;
$color-background-brand: $color-swx-orange;
$color-background-button: $color-swx-orange;
$color-background-btnhover: $color-orange-dark;
$color-background-btnDrk: $color-grey-dark;
$color-background-btnDisabled: $color-grey-slate;
$color-background-pre: #eee;
$color-background-ins: #fff9c0;

$color-text-screen: $color-grey-dark;
$color-text-input: $color-grey-slate;
$color-text-input-focus: $color-grey-dark;
$color-text-main: #404040;
$color-text-dk: $color-grey-dark;
$color-text-md: $color-grey-slate;
$color-text-lt: $color-base-offWhite;
$color-text-wt: $color-base-white;
$color-text-brand: $color-swx-orange;

$color-link: $color-swx-orange;
$color-linkDk: $color-grey-dark;
$color-linkLt: $color-base-offWhite;
$color-linkWt: $color-base-white;
$color-link-visited: $color-swx-orange;
$color-link-hover: $color-swx-orange;
$color-link-hoverLt: $color-base-offWhite;
$color-link-hoverDk: $color-grey-dark;

$color-border-button: #ccc #ccc #bbb;
$color-border-button-hover: #ccc #bbb #aaa;
$color-border-button-focus: #aaa #bbb #bbb;
$color-border-input: $color-grey-light;
$color-border-abbr: $color-grey-slate;
$color-border-dk: $color-grey-dark;
$color-border-wt: $color-base-white;

$color-border: $color-swx-orange;