$userMenu-size: 31px;
$userMenu-dot-size: $userMenu-size / 6;

@mixin userMenu-circle($circleColor, $circleSize) {
    content: "";
    display: block;
    width: $circleSize;
    height: $circleSize;
    border-radius: $circleSize;
    background-color: $circleColor;
    box-shadow: 
        $circleColor 0 ($circleSize * -2) 0,
        $circleColor 0 ($circleSize * 2) 0;
    transition: .8s;
}

.userMenu {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:hover {
        .subMenu {
            display: none;
        }
    }
}

.userMenu-control {
    display: none;
}

.userMenu-icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    width: ($userMenu-dot-size * 4);  
    height: 100%;
    margin: 0;
    position: relative;
    z-index: 2;

    &::before {
        @include userMenu-circle($color-background-brand, $userMenu-dot-size);
    }
}

.userMenu-control:checked {
    ~ .userMenu-icon::before {
        width: 100%;
        background-color: $color-background-bodyWt;
        box-shadow: $color-background-bodyWt 0 0 0;
    }
    // ~ .userMenu-icon {
    //     background-color: $color-background-bodyWt;
    // }
    // ~ .userMenu-icon:before,
    // ~ .userMenu-icon span:before,
    // ~ .userMenu-icon:after {
    //     background-color: $color-background-bodyWt;
    //     //move all icons into the center
    //     top: $userMenu-size / 2 - $userMenu-dot-size / 2
    // }
    // ~ .userMenu-icon span:before {
    //     width: $userMenu-size / 2;
    //     left: $userMenu-size / 2 - $userMenu-dot-size - $userMenu-dot-size / 2;
    // }
    ~ .subMenu {
        animation: fade_in 0.1s forwards;
        display: block;
        height: auto;
        transition: height 0.25s, opacity 0.3s;
    }
}