$size-site-main: 100%;
$size-site-sidebar: 25%;
$size-site-max: 1170px;

$layer-back: 1;
$layer-back-md: 100;
$layer-back-ft: 500;
$layer-mid-bk: 1000;
$layer-mid: 1100;
$layer-mid-ft: 1500;
$layer-front-bk: 2000;
$layer-front-md: 2100;
$layer-front: 2500;
$layer-top: 10000;