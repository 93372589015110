.iframe {
    background-color: $color-background-bodyWt;
    border-radius: 15px;
    padding: 15px 20px;
}
.iframe_dark {
    background-color: $color-background-bodyDk;
    & > * {
        border-radius: 10px;
        overflow: hidden;
    }
}

.iframe iframe {
    width: 100%;
    height: 100%;
}

.mix-iframe_formHeight {
    position: relative;
    
    & > iframe {
        width: 100%;
        min-height: 1820px;
        @include breakpoint(tablet) {
            min-height: 1650px;
        }
    }
}