.wrapper {
    max-width: $size-site-max;
    margin: 0 auto;
    padding: 0 15px;
}

.wrapper + .wrapper {
    margin-top: 4%;
}

.wrapper > * {
    margin-bottom: 25px;
}

.wrapper_nearFull {
    max-width: 85%;
}

.wrapper_midLimit {
    max-width: 1020px;
}

.wrapper_small {
    max-width: 960px;
}