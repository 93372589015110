.btns {
    width: 100%;
    & > * {
        background-color: $color-background-brand;
        border-radius: 3px;
        box-sizing: border-box;
        color: $color-text-wt;
        display: inline-block;
        font-size: $font-size-med;
        padding: 10px;
        width: 100%;
        text-align: center;
        text-decoration: none;
    }
    & > *:hover,
    & > *:visited,
    & > *:focus {
        text-decoration: inherit;
        color: $color-text-wt;
        outline: none;
    }
    & > *:hover {
        background-color: $color-background-btnhover;
        cursor: pointer;
    }
    & > *[disabled] {
        background-color: $color-background-btnDisabled;
        color: $color-text-lt;
        cursor: auto;
    }
}

.btns_med > * {
    max-width: 100%;
    @include breakpoint(tablet) {
        max-width: 50%;
    }
}

.btns_short > * {
    width: auto;
    padding: 10px 25px;
}

.btns_lg a {
    padding: 15%;
    font-size: $font-size-biggest;
    line-height: 2.5rem;
}

.mix-btns_drk {
    & > * {
        background-color: $color-background-btnDrk;
    }
    & > *:hover {
        background-color: $color-background-button;
    }
}

// Overrides css for wpForm buttons
.customWPForm .wpforms-form .wpforms-submit-container button.form-btns {
    background-color: $color-background-brand;
    border-radius: 3px;
    border: none;
    box-sizing: border-box;
    color: $color-text-wt;
    display: inline-block;
    font-size: $font-size-med;
    padding: 10px;
    width: 100%;
    text-align: center;
    text-decoration: none;
}
.customWPForm .wpforms-form .wpforms-submit-container button.form-btns:hover,
.customWPForm .wpforms-form .wpforms-submit-container button.form-btns:visited {
    text-decoration: inherit;
    color: inherit;
}
.customWPForm .wpforms-form .wpforms-submit-container button.form-btns:hover {
    background-color: $color-background-btnhover;
    border: none;
}

.customWPForm .wpforms-form .wpforms-submit-container button.form-btns_med {
    max-width: 100%;
    @include breakpoint(tablet) {
        max-width: 50%;
    }
}

.customWPForm .wpforms-form .wpforms-submit-container button.form-btns_short {
    width: auto;
    padding: 10px 25px;
}

.customWPForm .wpforms-form .wpforms-submit-container button.form-btns_lg {
    padding: 15%;
    font-size: $font-size-biggest;
    line-height: 2.5rem;
}
