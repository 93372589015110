/*
 * Sets up overall breakpoints for four settings allows for rapid in document callouts
 */
 @mixin breakpoint($point) {
    @if $point == desktop {
        @media (min-width: 1040px) { @content ; }
    }
    @else if $point == tablet {
        @media screen and (min-width: 768px) { @content ; }
    }
    @else if $point == mobile {
        @media screen and (min-width: 320px) { @content ; }
    }
    @else if $point == mobileonly {
        @media screen and (max-width: 480px) { @content ; }
    }
}