.contentBlock {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.contentBlock_algRight {
    text-align: right;
}

.contentBlock-hd {
    margin-bottom: 28px;
    padding: 5px 0 0;
    width: 100%;
    @include breakpoint(tablet) {
        padding: 5px 3% 0;
    }
    & > * {
        border-bottom: solid 1px $color-border;
        margin-bottom: 28px;
        max-width: 1170px;
        margin: 0 auto;
        padding: 10px 0;
    }
}

.contentBlock-hd_flip > * {
    color: $color-text-brand;
    border-bottom-color: $color-border-wt;
}

.contentBlock-hd_block {
    background-color: $color-background-brand;
    border: none;
    box-shadow: inset 0px 10px 25px -10px rgba(0,0,0,1);
    max-width: 100%;
    padding: 5px 3%;
    & > * {
        text-align: center;
    }
}

.contentBlock-hd-intro {
    font-size: $font-size-med;
    font-weight: 100;
    @include breakpoint(tablet) {
        font-size: $font-size-biggest;
    }
}

.contentBlock-bd-intro{
    font-size: $font-size-smaller;
    @include breakpoint(tablet) {
        margin-top: 10px;
        font-size: $font-size-med;
    }
    @include breakpoint(desktop) {
        margin-top: 10px;
        font-size: $font-size-big;
    }
}

.contentBlock-hd-callout {
    font-size: $font-size-biggest;
    @include breakpoint(tablet) {
        font-size: $font-size-huge;
    }
}

.contentBlock-bd {
    font-size: 1rem;
    max-width: 1170px;
    width: 100%;
    margin: 0 auto;
    flex-grow: 2;
    & > p:first-child,
    & > p:last-child {
        margin-top: 0;
    }
    & > p:before {
        display: none;
    }
}

.contentBlock-bd_push {
    padding: 0 3%;
    @include breakpoint(tablet) {
        padding-left: 9%;
        padding-right: 3%;
    }
}
.contentBlock-bd_flush {
    padding: 0 3%;
}

.contentBlock-bd_block {
    padding: 0 3%;
    @include breakpoint(tablet) {
        padding: 0 6%;
    }
}

.mix-contentBlock-hd_label {
    padding: 0;
    margin: 0;
    & > * {
        border: none;
        color: $color-text-lt;
    }
}

.mix-contentBlock-hd_thick {
    border-bottom-width: 3px;
}

.mix-contentBlock-bd_brand {
    color: $color-text-brand;
}

.mix-contentBlock-bd_clean > * {
    margin: 0;
    padding: 0;
}

.contentBlock-tile {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 3%;
    & * + * {
        margin-left: 10px;
    }
}

.contentBlock-tile-item {
    flex-grow: 1;
    background-size: cover;
    background-position: center;
    &:before {
        content: '';
        display: block;
        width: 100%;
        padding-top: 2px / 3px * 100%;
    }
}

.contentBlock-bd_mediaLeft {
    width: 65%;
}
.contentBlock-mediaLeft {
    display: block;
    box-sizing: border-box;
    padding-left: 3%;
    width: 35%;
    & > * {
        padding-top: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
    }
}

.center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: flex;
}

.container {
    height: 50px;
    position: relative;
}