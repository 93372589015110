.alertMsg {
    background-color: $color-background-body;
    position: fixed;
    top: 107px;
    z-index: 2000;
    padding: 1% 5%;
    width: 100vw;
    text-align: center;
    font-size: 1.125rem;
    
    a {
        color: $color-text-brand;
    }
}