a {
	color: $color-link;
	text-decoration: none;

	&:visited {
		color: $color-link-visited;
	}
	&:hover,
	&:focus,
	&:active {
		color: $color-link-hover;
		text-decoration: underline;
	}
	&:focus {
		outline: thin dotted;
	}
	&:hover,
	&:active {
		outline: 0;
	}
}

.link_clean {
	text-decoration: none;
}

.mix-link_invert {
	color: $color-base-white;

	&:visited {
		color: $color-base-white;
	}
	.link-label {
		color: $color-link;
	}
	.link-label:hover {
		color: $color-base-white;
	}
}