.list {
    display: flex;
}

.list_vert {
    flex-direction: column;
}

.list_vert > * {
    box-sizing: border-box;
}

.list_vert > * + * {
    padding-top: 5px;
}

.mix-list_gapMd > * + * {
    padding-top: 30px;
}

.mix-list_spread {
    align-content: space-between;
    justify-content: space-between;
}