/*
*   Primary Navigation 
*/
.mainNav {
  background-color: $color-tint-heavy;
  padding: 0 25px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: $layer-top;
  width: 100vw;
}

.mainNav-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: #000000;
  max-width: $size-site-max;
  margin: 0 auto;
  position: relative;
  @include breakpoint(desktop) {
    max-width: 80%;
  }
}

.mainNav-wrap-left {
  width: 20%;
  max-width: 250px;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  color: #000000;
}

.mainNav-wrap .mainNav-wrap-toggle {
  color: #000000;
  border: none;
}

.mainNav-wrap-menu {
  position: absolute;
  right: -25px;
  top: 100%;
  background-color: #58595B;
  width: 116%;
  @include breakpoint(tablet) {
    right: -10px;
    width: 105%;
  }
  @include breakpoint(desktop) {
    position: relative;
    background-color: transparent;
    width: 75%;
  }
}