.social {
    margin-left: -15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social > * {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    fill: $color-background-brand;
    flex-grow: 1;
    margin-left: 15px;
    max-width: 20%;
    height: 21px;
    width: 21px;
    &:hover {
        fill: $color-background-bodyWt;
    }
    & > * {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
    }
}
